import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as styles from './block.module.scss';

const Box = styled.div`
	height: ${props => props.height};
`;

export class Block extends React.Component {
  

    getContainerClass() {
    	const cont = this.props.goingUp ? `${styles.container} ${styles.goingUp}` : `${styles.container} ${styles.goingDown}`;
    	return this.props.open ? cont : `${cont} ${styles.notOpen}`;
    }

    
    render() {
        return (

            <Box  className={this.getContainerClass()} height={this.props.height}>

            	{this.props.children}
            	

            </Box>
        );
    }
}

Block.propTypes = {
    open: PropTypes.bool.isRequired,
    height: PropTypes.string.isRequired,
    goingUp: PropTypes.bool.isRequired,

};

Block.defaultProps = {
  goingUp: true,  

};