import * as React from 'react';

import * as styles from './creditsMobile.module.scss';

const credits = [
    {name: "Maácz László: ", title: "Dus-tánc"},  
    ];

export class CreditsMobile extends React.Component {
  

    render() {
        return (
           
                <div className={styles.container}>
                    {credits.map((cr, i) => 
                        <div key={i} className={styles.credit}>
                            <p className={styles.name}>{cr.name}</p>
                            <p className={styles.title}>{cr.title}</p>
                        </div>)}
                    <div className={styles.desc}>A felvétel a Néprajzi Múzeum Fényképtárának tulajdonában áll.</div>
                    
                </div>

                    
         
        );
    }
}

