import * as React from 'react';

import * as styles from './credits.module.scss';

const credits = [
    {name: "K. Kovács László: ", title: "Csárdás"}, 
    {name: "Maácz László: ", title: "Dus-tánc"}, 
    {name: "Gönyey Sándor: ", title: "Csárdás térden állva"}, 
    {name: "Molnár Balázs: ", title: "Kocsma előtt"}
    ];

export class Credits extends React.Component {
  

    render() {
        return (
           
                <div className={styles.container}>
                    {credits.map((cr, i) => 
                        <div key={i} className={styles.credit}>
                            <p className={styles.name}>{cr.name}</p>
                            <p className={styles.title}>{cr.title}</p>
                        </div>)}
                    <div className={styles.desc}>A felvételek a Néprajzi Múzeum Fényképtárának tulajdonában állnak.</div>
                    
                </div>

                    
         
        );
    }
}

