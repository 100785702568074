import * as React from 'react';
import {Pages} from '../config/pages';
import * as styles from './elnoksegButton.module.scss';



export class ElnoksegButton extends React.Component {
  

    render() {
        return (
           
                <a href={Pages.elnokseg.admin} className={styles.elnoksegLink}>Elnökség</a>

                    
         
        );
    }
}

