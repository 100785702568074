import * as React from 'react';
import PropTypes from "prop-types";
import {Link} from "../../../common/link";
import styled from "styled-components";
import * as styles from './singleImageV.module.scss';





const InnerImage = styled.div`
          background-image: url(${props => props.menuItem.img});
          ${props => (props.inFocus) && "transform: scale(1.05, 1.05)"};
`;

export class SingleImageV extends React.Component {
    constructor(props) {
        super(props);

        
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        this.props.focusImg(this.props.index);
    }

    handleMouseLeave() {
        this.props.blurImg();
    }

    getTextClass() {
        return this.props.inFocus ? `${styles.text} ${styles.inFocus}` : styles.text;
    }

      

    render() {
        return (
            <Link location={this.props.menuItem.location} className={styles.link}>
                <div    
                    index={this.props.index} 
                    onMouseEnter={this.handleMouseEnter} 
                    onMouseLeave={this.handleMouseLeave} 
                    className={styles.image}>
                    
                    <InnerImage 
                        menuItem={this.props.menuItem} 
                        inFocus={this.props.inFocus} 
                        index={this.props.index} 
                        className={styles.innerImage}
                    />
                   
                    {this.props.index < 3 && <div className={styles.rightEdge}/>}
                    {this.props.index > 0 && <div className={styles.leftEdge}/>}
                    
                    <div className={styles.textContainer}>
                        <p className={this.getTextClass()}>{this.props.menuItem.name}</p>
                    </div>

                </div>
            </Link>
        );
    }
}

SingleImageV.propTypes = {
    index: PropTypes.number.isRequired,
    menuItem: PropTypes.object.isRequired,
    inFocus: PropTypes.bool.isRequired,
    focusImg: PropTypes.func.isRequired,
    blurImg: PropTypes.func.isRequired,


    
};
