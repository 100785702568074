import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

import * as styles from './mobileImage.module.scss';
import img1 from "../../img/landing/imgmobile.jpg";


const Image = styled.div`
  background-image: url(${img1});
  background-size:     cover;                  
  background-repeat:   no-repeat;
  background-position: center center;   

  width: 100vw;
  height: 100%;

`;

const ImgContainer = styled.div`

    height: ${props => props.containerHeight}px;
    width: 100vw;
`;

export class MobileImage extends React.Component {
    render() {
        return (
        	<div className={styles.container}>
	            <ImgContainer containerHeight={this.props.containerHeight}>

	              <Image  />
	           
	            </ImgContainer>
            </div>
        );
    }
}


MobileImage.propTypes = {

    containerHeight: PropTypes.number.isRequired,


       
};
