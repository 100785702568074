import React from "react";
import styled from "styled-components";
import {Page} from "../../../common/page";
import {FourImagesV} from "./fourImagesV";
import {Block} from "./block";
import {MobileImage} from './mobileImage';
import {Credits} from './credits';
import {CreditsMobile} from './creditsMobile';
import {LogoScreen} from './logoScreen';
import {FooterText} from '../footerText';
import {Title} from '../../../common/title';
import {ElnoksegButton} from '../../../common/elnoksegButton';

import Menu from '../../../common/menu';
import {blocksOpenDuration, blocksOpenDelay, showLogoScreenFor} from "../../../common/contentAux";
import * as styles from './homePage.module.scss';

const Container = styled.div`
    height: ${props => props.windowHeight}px;
    display: flex;
    align-items: flex-end;
`

;

export class HomePage extends React.Component {
 
  constructor(props) {
    super(props);
    this.title = React.createRef();

    this._isMounted = false;

    this.state = { 
      hheight: 0,                         // height of top block w/o units -- can't be responsive to menu opening
      headerTop: "50%",                   // height of top block
      headerBottom: "50%",                // height of bottom block   
      menuHeight: 0,                      //height of menu (expanded or not)

      displayLogoScreen: true,
      delayDone: false,
      blocksOpen: false,

      inFocus: -1,
    };

    this.setHeaderHeight = this.setHeaderHeight.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.setMenuHeight = this.setMenuHeight.bind(this);
    this.focusImg = this.focusImg.bind(this);
    this.blurImg = this.blurImg.bind(this);
  }

  handleResize() {
    let newWindowHeight = [this.state.windowHeight[1]];
    newWindowHeight.push(window.innerHeight);
    let newWindowWidth = [this.state.windowWidth[1]];
    newWindowWidth.push(window.innerWidth);

    //vertical resize: update windowHeight history but don't pass on current WH value
    if (newWindowWidth[0] === newWindowWidth[1] && newWindowHeight[0] !== newWindowHeight[1]) {
        this.setState({windowHeight: newWindowHeight});

    }

    //horizontal resize: update windowWidth history and update header height
    if (newWindowHeight[0] === newWindowHeight[1] && newWindowWidth[0] !== newWindowWidth[1]) {
        this.setState({windowWidth: newWindowWidth});
        this.setHeaderHeight();
    } 

    //screen rotate: update wH and wW history and pass on both values
    if (newWindowHeight[0] !== newWindowHeight[1] && newWindowWidth[0] !== newWindowWidth[1]) {
        this.setState({windowWidth: newWindowWidth, windowHeight: newWindowHeight, passedOnWH: newWindowHeight[1]});
        this.setHeaderHeight();
    } 
  }

  setHeaderHeight() {
    if (this._isMounted) {
        const titleHeight = this.title.current.getBoundingClientRect().height;
        const top = Math.max(titleHeight, this.state.menuHeight);
        this.setState({headerTop: `${top}px`, headerBottom: "0px", hheight: titleHeight});
    }
  } 

  setMenuHeight(h) {
    this.setState(
        {menuHeight: h}, 
        () => {this.setHeaderHeight();}
    );
  }

  focusImg(i) {
    this.setState({inFocus: i});
  }

  blurImg() {
    this.setState({inFocus: -1});
  } 

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      passedOnWH: window.innerHeight,
      windowHeight: [window.innerHeight, window.innerHeight],
      windowWidth: [window.innerWidth, window.innerWidth]
    })
    setTimeout(() => this._isMounted && this.setHeaderHeight() && this.setState({delayDone: true}), blocksOpenDelay);
    setTimeout(() => this._isMounted && this.setState({displayLogoScreen: false}), showLogoScreenFor);
    setTimeout(() => this._isMounted && this.setState({delayDone: true}), blocksOpenDelay);
    setTimeout(() => this._isMounted && this.setState({blocksOpen: true}), blocksOpenDelay + blocksOpenDuration);

    this._isMounted && window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);

    this._isMounted = false;
  }

  getLogoClass() {
    return this.state.delayDone ? `${styles.delayDone} ${styles.logo}` : styles.logo;
  }

  render() {
    if (!this._isMounted) {
      return null;
    }

    return (

      <Page>
        <Container windowHeight={this.state.passedOnWH}>
          <MobileImage containerHeight={this.state.passedOnWH - this.state.hheight}/>
          <FourImagesV 
              inFocus={this.state.inFocus} 
              focusImg={this.focusImg} 
              blurImg={this.blurImg} 
              containerHeight={this.state.passedOnWH - this.state.hheight}
          />
            
          <Block open={this.state.blocksOpen} height={this.state.headerTop}>
            <div className={styles.headerRow}>
                <Title ref={this.title} onHomepage={true}/>
                <div className={this.getLogoClass()}/>
                <Menu onHomepage={true} setMenuHeight={this.setMenuHeight} inFocus={this.state.inFocus} focusImg={this.focusImg} blurImg={this.blurImg} activePage={-1}/>
            </div>
          </Block>

          {!this.state.blocksOpen && <Block goingUp={false} open={this.state.blocksOpen} height={this.state.headerBottom}>
            <FooterText onHomepage={true}/>

          </Block>}
        </Container>

        {this.state.blocksOpen && 
            <div className={styles.footer}>
                <Credits/>
                <CreditsMobile/>
                <ElnoksegButton/>
            </div>
        }

        {this.state.displayLogoScreen && <LogoScreen windowW={this.state.windowWidth[1]} windowH={this.state.passedOnWH}/>}

      </Page>
    );
  }
}
