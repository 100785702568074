import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import * as styles from './logoScreen.module.scss';
import {showLogoScreenFor} from "../../../common/contentAux";

const showFor = `${showLogoScreenFor}ms`;

const Container = styled.div`
	height: ${props => props.height}px;
    width: ${props => props.width}px;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props => props.startFade && "filter: alpha(opacity=0); opacity: 0;"}
    -webkit-transition: opacity ${showFor} ease-in-out;
    -moz-transition: opactiy ${showFor} ease-in-out;
    -ms-transition: opacity ${showFor} ease-in-out;
    -o-transition: opacity ${showFor} ease-in-out;
    transition: opacity ${showFor} ease-in-out;
`;

export class LogoScreen extends React.Component {
  constructor(props) {
        super(props);

        this.state = { 
            startFade: false,
        };
    }

    componentDidMount() {
        setTimeout(() => this.setState({startFade: true}), showLogoScreenFor/3);

    }
    
    render() {
        return (

            <Container height={this.props.windowH} width={this.props.windowW} startFade={this.state.startFade}>
                <div className={styles.logo}/>
            </Container>
        );
    }
}

LogoScreen.propTypes = {
    windowH: PropTypes.number.isRequired,
    windowW: PropTypes.number.isRequired,

};

