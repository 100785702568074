import * as React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";

import * as styles from './fourImagesV.module.scss';
import {SingleImageV} from "./singleImageV";
import {menuItems} from "../../../common/contentAux";

const ImgContainer = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    height: ${props => props.containerHeight}px;
`;



export class FourImagesV extends React.Component {
  

    render() {
        return (
            <div className={styles.outerContainer}>

                <ImgContainer containerHeight={this.props.containerHeight}>
                    {menuItems.map((item, i) => <SingleImageV key={i} index={i} menuItem={item} inFocus={this.props.inFocus === i} focusImg={this.props.focusImg} blurImg={this.props.blurImg}/>)}
                </ImgContainer>    
                    
            </div>
        );
    }
}

FourImagesV.propTypes = {

    containerHeight: PropTypes.number.isRequired,
    inFocus: PropTypes.number.isRequired,
    focusImg: PropTypes.func.isRequired,
    blurImg: PropTypes.func.isRequired,

       
};
